










import { Component, Vue } from 'vue-property-decorator'
import { CartStep, CartStore } from '~/store/cart'

const CartReview = () => import('@embed/components/CartReview.vue')
const CartPaymentFormPaypalDirect = () => import('~/components/CartPaymentFormPaypalDirect.vue')

@Component({
    components: {
        CartReview,
        CartPaymentFormPaypalDirect
    }
})
export default class CartPage extends Vue {
    CartStep = CartStep

    @CartStore.State('cartStep') cartStep: number

    mounted() {
        this.$store.commit('cart/SET_CART_STEP', CartStep.Review)
    }
}
