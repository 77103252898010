







import { Component, Mixins } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { AbstractFinishPaypal } from '~/mixins/finishPaypal'

@Component({ components: { VLoadSpinner } })
export default class MarketplaceFinishPaypal extends Mixins(AbstractFinishPaypal) {
}
